.containerSearch {
    width: 100%;
    margin: auto;
    padding-top: 3px;
}

.chatContainer {
    width: 50%;
    margin: auto;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: white;
    padding: 0px 33px 33px 33px;
    border-radius: 0 0 15px 15px;
    overflow-y: auto;
    margin-bottom:10px;
}
.chatHeaderContainer{
    width: 50%;
    height: 10vh;
    display: flex;
    padding: 10px 33px;
    margin-bottom: 0;
    background-color: #F8F8F8;
    border-radius: 15px 15px 0 0 ;
    align-items: center;
}

.chatHeaderContainer h3{
    margin-left: 15px;
}

.chatHistory {
    /* flex-grow: 1; */
    overflow-y: auto;
    margin-bottom: 10px;
    overflow-y: scroll;
    /* display: flex;
    flex-direction: column;
    justify-content: end; */
}

.chatBubbleContainer {
    display: flex;
    justify-content: column;
    margin-bottom: 10px;
}

.botBubble {
    border-radius: 10px;
    padding: 10px;
    max-width: 70%;
    align-self: flex-start;
    display: flex;
}

.userBubble {
    border-radius: 10px;
    padding: 10px;
    max-width: 70%;
    align-self: flex-end;
}

.inputArea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.feedbackArea {
    display: flex;
    justify-content: start;
    margin-top: 10px;
    padding-left: 10px;
}

.likeIcon {
    font-size: 24px;
    color: green;
    cursor: pointer;
    margin-right: 10px;
}

.dislikeIcon {
    font-size: 24px;
    color: red;
    cursor: pointer;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
}

.title {
    margin-bottom: 5px !important;
    color: var(--primaryLight) !important;
    background-color: var(--gray);
    padding: 0px 10px 4px;
    border-radius: 8px;
}

.alerts {
    top: 30px;
    width: 50%;
}

body {
    background-color: lightblue;
}
.chatBubble{
    display: flex;
    flex-direction: column;
}
.userQuestion{
    color: black!important;
    /* width: 50%; */
    /* margin-left: 50%; */
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid lightgray;
    padding: 7px;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom:10px;
    opacity: 1;
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    word-break: break-word; 
    white-space: normal; 
    overflow: hidden; 
}
.botResponse{
    color: black!important;
    /* width: 50%; */
    border-radius: 7px;
    border: 1px solid lightgray;
    padding: 7px;
    font-size: 14px;
    height: 100%;
}
.response{
    color: black!important;
    padding: 7px;
    font-size: 14px;
    background-color: rgba(153, 186, 88, 0.33);
    border-radius: 7px;
    border: 1px solid lightgray;
}
.responseArea{
    /* width: 50%; */
    border-radius: 7px;
    padding: 4px;
    height: 11%;
}

.botAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

.typingIndicator {
    display: flex;
    align-items: center;
}

.bouncingDots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}

.bouncingDots span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #000; /* Adjust the color as needed */
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
}

.bouncingDots span:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncingDots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    to {
        opacity: 0.3;
        transform: translateY(-4px);
    }
}

.logo{
    margin-bottom: 10px;
}

.delfinaName{
    margin-top:0;
    margin-right: 10px;
    font-style: italic;
    font-size: small;
}