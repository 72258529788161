.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--gray);
    flex-direction: column;
    align-items: center;  
}

.title{
    font-size: 50px;
    color: var(--primary);
}

.button {
    padding-top: 10%;

}
